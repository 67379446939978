module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"invalidateConsentWithoutLog":true,"perPurposeConsent":true,"reloadOnConsent":true,"siteId":2808029,"whitelabel":false,"cookiePolicyId":37205793,"lang":"it","cookiePolicyUrl":"https://ambulatorioveterinariopisa.it/privacy","banner":{"acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-top-center","rejectButtonDisplay":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ambulatorio-veterinario-pisa","short_name":"ambulatorio","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/ambulatorio-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"891d68305f42d38c422fdb5bb35eabb7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
